<template>
    <div class="updata_box">
        <div style="width: 100%;height: 50px;"></div>
        <div class="updata_box_cenete">
            <div class="updata_div_top">
                <div style="display: flex;">
                    <div style="position: relative; margin: 0 40px;">
                        <img style="width: 268px; height: 29px;" src="../../../../static/wenchang/图层 5 拷贝 6@2x.png" alt="">
                        <span :class="isactive == 1 ? 'personala' : 'personal'" style="width: 110px; position: absolute;left: 56px;" @click="personal(1)">创意设计组</span>
                    </div>
                    <div style="position: relative;margin: 0 40px;">
                        <img style="width: 268px; height: 29px;" src="../../../../static/wenchang/图层 5 拷贝 6@2x.png" alt="">
                        <span :class="isactive == 2 ? 'personala' : 'personal'" style="width: 110px; position: absolute;left: 56px;" @click="personal(2)">文创产品组</span>
                    </div>
                </div>
            </div>
            <div class="updata_div" v-if="isactive == 1">
                <div class="updata_div_toptext2">
                    <img style="width: 156px; height: 84px;" src="../../../../static/wenchang/金奖.png" alt="">
                </div>
                <img style="width: 100%;" src="../../../../static/wenchang/图层 37 拷贝 2@2x.png" alt="">
                <div class="zuopinglist">
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/d6/f902888cc54519bf39661b0bef2e49.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                黎之歌</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:胡颖辉</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('394')">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="updata_div_toptext3" style="margin-top: 120px; position: relative;">
                    <img style="width: 156px; height: 84px; position: absolute; left: 43%;top: -31%;" src="../../../../static/wenchang/银奖.png" alt="">
                    <img style="width: 100%;" src="../../../../static/wenchang/图层 37 拷贝 2@2x.png" alt="">
                </div>
                <div class="zuopinglist">
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1720347828_%E7%A7%80%E8%8B%B1%E7%81%AB%E8%8D%94%E5%B0%8F%E5%A4%9C%E7%81%AF%20%E6%8B%B72%E8%B4%9D.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                秀英火荔小夜灯</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:林界平</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('298')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1723992836_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240818141651.png" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                南海藏宝册</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:张洁</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('508')">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="updata_div_toptext3" style="margin-top: 120px; position: relative;">
                    <img style="width: 156px; height: 84px; position: absolute; left: 43%;top: -31%;" src="../../../../static/wenchang/铜奖@2x.png" alt="">
                    <img style="width: 100%;" src="../../../../static/wenchang/图层 37 拷贝 2@2x.png" alt="">
                </div>
                <div class="zuopinglist">
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1719028665_%E9%9B%86%20(1).png" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                黎鹿杯</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:李家荣</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('232')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1723534187_%E7%90%BC%E6%9B%B2%C2%B7%E6%9E%9C%E5%91%B3%E5%B1%95%E6%9D%BF.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                琼曲·果味</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:林晓瑜</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('60')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1723911684_%E9%94%A6%E4%B8%8A%E8%8A%B1%20%E5%B0%81%E9%9D%A2.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                锦上花</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:董璐南</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('475')">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="updata_div_toptext3" style="margin-top: 120px; position: relative;">
                    <img style="width: 156px; height: 84px; position: absolute; left: 43%;top: -31%;" src="../../../../static/wenchang/优秀奖@2x.png" alt="">
                    <img style="width: 100%;" src="../../../../static/wenchang/图层 37 拷贝 2@2x.png" alt="">
                </div>
                <div class="zuopinglist">
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1723634618_1%20(1).jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                一鲸落</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:王雨</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('436')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1723995318_%E9%BB%8E%E6%B5%B7%E7%89%A9%E5%A3%B0.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                黎海物声</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:陈建明</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('455')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1723876455_%E9%87%91%E7%89%9B%E7%BA%B3%E7%A6%8F5.130.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                金牛纳福</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:陶成龙</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('468')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1719738199_%E6%B5%B7%E5%8D%97%E5%B1%95%E6%9D%BF%E8%AE%BE%E8%AE%A1-02.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                海南黎族纹样插画及衍生品设计</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:王雪婷</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('269')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1e/cf5af377b634e4452d5ac282cd7eb4.png" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                印花集</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:杨志恒</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('181')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1721119971_IMG_6197(1).png" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                琼曲新篇-书籍设计</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:付媛、王玥妍、肖思莹</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('337')">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="updata_div" v-if="isactive == 2">
                <div class="updata_div_toptext2">
                    <img style="width: 156px; height: 84px;" src="../../../../static/wenchang/金奖.png" alt="">
                </div>
                <img style="width: 100%;" src="../../../../static/wenchang/图层 37 拷贝 2@2x.png" alt="">
                <div class="zuopinglist">
                    <div style="font-weight: 600;font-size: 22px;color: #333333;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-top: 60px;">无获奖作品</div>
                    <!-- <div class="zuopingitem" style="margin: 50px auto;"> -->

                    <!-- <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/d6/f902888cc54519bf39661b0bef2e49.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                黎之歌</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:胡颖辉</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('394')">
                            </div>
                        </div> -->
                    <!-- </div> -->
                </div>

                <div class="updata_div_toptext3" style="margin-top: 120px; position: relative;">
                    <img style="width: 156px; height: 84px; position: absolute; left: 43%;top: -31%;" src="../../../../static/wenchang/银奖.png" alt="">
                    <img style="width: 100%;" src="../../../../static/wenchang/图层 37 拷贝 2@2x.png" alt="">
                </div>
                <div class="zuopinglist">
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1728873048_%E4%B8%9C%E6%96%B9%E7%BB%87%E5%A8%98-%E6%89%8B%E6%91%87%E9%9F%B3%E4%B9%90%E7%9B%92.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                《东方织娘》系列手摇音乐盒</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:东方乡村振兴投资有限公司</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('521')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1722913117_2024%E6%AF%95%E4%B8%9A%E8%AE%BE%E8%AE%A1%E5%B1%95%E6%9D%BF%E6%A8%A1%E6%9D%BF-01.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                地域文化元素在“画里屯昌”文创产品设计中的应用</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:凌雀</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('387')">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="updata_div_toptext3" style="margin-top: 120px; position: relative;">
                    <img style="width: 156px; height: 84px; position: absolute; left: 43%;top: -31%;" src="../../../../static/wenchang/铜奖@2x.png" alt="">
                    <img style="width: 100%;" src="../../../../static/wenchang/图层 37 拷贝 2@2x.png" alt="">
                </div>
                <div class="zuopinglist">
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1719672958_%E5%BE%82%E5%B7%9D%E5%B1%95%E6%9D%BF1.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                “徂川”系列陶瓷餐具设计</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:孙驰</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('265')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1720584784_1.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                定安有“戏”国家级非遗文创设计——海南琼剧</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:龙舟、周媛、陈碧钰</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('325')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1723791569_%E5%8D%97%E6%B5%B7%E7%89%A9%E8%AF%AD%E5%B0%81%E9%9D%A2.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                【南海物语】系列文创产品</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:孟晓文、刘潇涵</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('459')">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="updata_div_toptext3" style="margin-top: 120px; position: relative;">
                    <img style="width: 156px; height: 84px; position: absolute; left: 43%;top: -31%;" src="../../../../static/wenchang/优秀奖@2x.png" alt="">
                    <img style="width: 100%;" src="../../../../static/wenchang/图层 37 拷贝 2@2x.png" alt="">
                </div>
                <div class="zuopinglist">
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/b2/80496a15ed3559012e2a594e9c324a.png" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                海南黎族文创设计</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:周彩云</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('145')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1723556944_%E6%B5%B7%E5%8D%97%E5%8D%9A%E7%89%A9%E9%A6%86A1.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                黎兽锦纹</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:唐荣</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('429')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1723190540_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240809155936.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                林生花·海南风物香薰-包装系列</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:张晓芳</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('409')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1723989584_54%E9%A1%B5.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                红色海南</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:张琪</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('491')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1723904829_%E5%A5%87%E5%A6%991-12%20[%E5%B7%B2%E6%81%A2%E5%A4%8D]_%E7%94%BB%E6%9D%BF%201.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                奇妙海洋文创系列</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:章家斌</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('472')">
                            </div>
                        </div>
                    </div>
                    <div class="zuopingitem" style="margin: 50px auto;">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100%" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/test/1723895831_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240817195608.jpg" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                黎锦双生</div>
                            <div style="font-weight: 600;font-size: 18px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                <span>作者:吴秉熹、黄小雅</span>
                            </div>
                            <div class="toupiao">
                                <img style="width: 100%;height: 100%;" src="../../../../static/wenchang/查看.png" alt="" @click="gotoxiangq('474')">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%;height: 50px;"></div>
        <img class="updata_bottom" src="../../../../static/wenchang/4.png" alt="">
    </div>
</template>

<script>
import { selectPageList, artworkvote } from "../../../util/api"
export default {
    name: 'science_child2',

    data() {
        return {
            value: '',
            page: 1,
            currpage: 9,
            totalCount: 0,
            fileList2: [],
            isactive: 1,
            keyWord: '',
            actualType: '创意设计组',
            userId: localStorage.getItem('useinfo')
        }
    },
    mounted() {

    },
    created() {
        this.getlist()
    },
    methods: {
        gotoxiangq(val) {
            window.open('https://wenchuang-web.123bingo.cn/#/science_child7?id=' + val + '&liu=1', '_blank');
        },
        gotopiao(val) {
            this.$confirm('每人只有6次投票机会, 是否投票?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                artworkvote({
                    artworkId: val.id,
                    userId: localStorage.getItem('useinfo'),
                }).then((res) => {
                    if (res.data.message) {
                        this.$message({
                            message: res.data.message,
                            type: 'warning'
                        });
                    } else {
                        this.$message({
                            message: '提交成功！',
                            type: 'success'
                        });
                        this.getlist()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        personal(val) {
            this.isactive = val
            // this.page = 1
            // if (val == 1) {
            //     this.actualType = '创意设计组'
            // } else if (val == 2) {
            //     this.actualType = '文创产品组'
            // }
            // this.getlist()
        },
        getlist() {
            selectPageList({
                page: this.page,
                size: this.currpage,
                keyWord: this.keyWord,
                enroll: 1,
                actualType: this.actualType,
                userId: this.userId,
                soldOut: 0,
            }).then((res) => {
                console.log(res.data);
                const arr = []
                res.data.artworkInfoDao.forEach(item => {
                    if (item.cover) {
                        arr.push(item)
                    }
                });
                this.fileList2 = arr;
                this.totalCount = res.data.total;
            })
        },
        handleSizeChange(cpage) {
            this.currpage = cpage;
            this.getlist();
        },
        //分页按钮 首页和最后一页
        handleCurrentChange(cpage) {
            if (this.keyWord) {
                this.page = 1;
            } else {
                this.page = cpage;
            }
            this.getlist();
        },
    }
}
</script>
<style scoped>
.submits {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-bottom: 100px;
  padding-top: 50px;
}

.submit {
  width: 100%;
  text-align: center;
  padding-bottom: 100px;
  padding-top: 50px;
  cursor: pointer;
}

.updata_bottom {
  /* position: fixed;
    bottom: 0 */
  width: 100%;
  margin-bottom: -6px;
}

.updata_box_cenete {
  margin: 61px auto;
  width: 1311px;
  /* height: 1800px; */
  background: #ffffff;
  border-radius: 30px;
}

.updata_box {
  background-image: url("../../../../static/wenchang/8.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.updata_div {
  position: relative;
  padding: 0 71px;
  padding-top: 42px;
  width: 100%;
  height: 100%;
}

.updata_div_toptext {
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  color: #333333;
}

.updata_div_toptext2 {
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  color: #333333;
}

.input1 {
  width: 100%;
  display: flex;
  align-items: center;
}

.input2 {
  width: 100%;
  display: flex;
  align-items: center;
}

.input3 {
  width: 100%;
  display: flex;
}

.select1 {
  display: flex;
  align-items: center;
}

.select2 {
}

.input3uplodtxt {
  font-weight: 100;
  font-size: 14px;
  color: #f34242;
  margin-top: 20px;
  margin-left: 152px;
}

.el-upload__tip {
  font-size: 14px;
  color: #f34242;
}

.personal {
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
  opacity: 0.5;
  margin: 0 20px;
}

.personala {
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
  margin: 0 20px;
}

.personal_box {
  position: absolute;
  top: 7%;
  left: 7%;
}

@media (max-width: 480px) {
}

@media (max-width: 5000px) {
  p {
    font-size: 14px !important;
  }

  .updata_div_top {
    width: 100%;
    height: 96px;
    background: #43a1cc;
    border-radius: 30px 30px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .zuopinglist {
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .zuopinglist .zuopingitem:last-child:nth-child(3n-2) {
    margin-right: calc(60% + (10% / 4) * 2);
  }

  .zuopinglist .zuopingitem:last-child:nth-child(3n-1) {
    margin-right: calc(30% + (10% / 4) * 1);
  }

  .zuopingitem {
    cursor: pointer;
    text-align: center;
    width: 330px;
    height: 439px;
    background-image: url("../../../../static/wenchang/575.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .zuopingitem_img {
    padding: 11px;
    padding-bottom: 0px;
    width: 100%;
    height: 190px;
  }

  .zuopingitem_txt {
    margin: 0px 11px;
    background: #ffffff;
    border-radius: 0px 0px 16px 16px;
    border: 1px solid #ebca92;
    text-align: left;
    padding-bottom: 7px;
  }

  .zuopingitem_txt div {
    padding: 8px 0;
  }

  .toupiao {
    width: 133px;
    height: 42px;
    margin: 29px auto;
    padding: 0 !important;
  }
}
</style>
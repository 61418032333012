import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'pubpage',
		redirect: '/',
		component: () => import('../components/pubpage.vue'),
		children: [
			{
				path: '/',
				name: 'hometwo',
				component: () => import('../views/hometwo.vue'),
				meta: { title: '首页' }
			},
			// 科技超威
			{
				path: '/science',
				name: 'science',
				component: () => import('../views/science/science_child1/science_child1.vue'),
				meta: { title: '1' }
			},
			// 科技超威
			{
				path: '/science_child1',
				name: 'science_child1',
				component: () => import('../views/science/science_child1/science_child1.vue'),
				meta: { title: '2' }
			},
			// 科技超威
			{
				path: '/science_child2',
				name: 'science_child2',
				component: () => import('../views/science/science_child2/science_child2.vue'),
				meta: { title: '3' }
			},
			// 科技超威
			{
				path: '/science_child3',
				name: 'science_child3',
				component: () => import('../views/science/science_child3/science_child3.vue'),
				meta: { title: '4' }
			},
			// 科技超威
			{
				path: '/science_child4',
				name: 'science_child4',
				component: () => import('../views/science/science_child4/science_child4.vue'),
				meta: { title: '5' }
			},
			{
				path: '/science_child5',
				name: 'science_child5',
				component: () => import('../views/science/science_child5/science_child5.vue'),
				meta: { title: '6' }
			},
			{
				path: '/science_child6',
				name: 'science_child6',
				component: () => import('../views/science/science_child6/science_child6.vue'),
				meta: { title: '7' }
			},
			{
				path: '/science_child7',
				name: 'science_child7',
				component: () => import('../views/science/science_child7/science_child7.vue'),
				meta: { title: '8' }
			},
			{
				path: '/science_child8',
				name: 'science_child8',
				component: () => import('../views/science/science_child8/science_child8.vue'),
				meta: { title: '9' }
			},
			{
				path: '/science_child9',
				name: 'science_child9',
				component: () => import('../views/science/science_child9/science_child9.vue'),
				meta: { title: '10' }
			},
			{
				path: '/science_child10',
				name: 'science_child10',
				component: () => import('../views/science/science_child10/science_child10.vue'),
				meta: { title: '10' }
			},
			{
				path: '/culture',
				name: 'culture',
				component: () => import('../views/culture/culture.vue'),
				meta: { title: '1' }
			},
			{
				path: '/construction',
				name: 'construction',
				component: () => import('../views/culture/construction.vue'),
				meta: { title: '1' }
			},
		]
	},

]

const router = new VueRouter({
	//   mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
